<template>
    <div class="container-fluid">

        <div class="box-header">
            <div class="row">
                    <div class="col-md-4">
                        <h4 class="title-in-h">{{$t('annulment.index.title')}}</h4>
                        <label v-show="info_page.status===true">{{$t('annulment.index.has')+' '+ info_page.actives+' '+$t('annulment.index.a_has_plural') }}</label>
                        <label v-show="info_page.status===false">{{$t('annulment.index.has')+' '+ info_page.inactives+' '+$t('annulment.index.i_has_plural') }}</label>
                    </div>
                    <div class="col-md-5 pt-2">
                        <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t('form.actives')}}</button>
                        <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t('form.inactives')}}</button>
                    </div>
            </div>
        </div>



    <div class="row">

        <div class="col-12">
            <card v-loading="screenLoading"
                  element-loading-lock="true"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-" style="width: 280px">

                            <l-button class="btn-default" @click="createItem()"  v-if="writting_permission">{{$t('annulment.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t('annulment.index.search') "
                                  v-model="searchQuery"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">

                        <el-table class="table-bigboy"
                                  style="width: 100%"
                                  :data="queriedData">
                            <el-table-column min-width="100"  :label="$t('annulment.table.name') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container  text-center">
                                        <label>{{row.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t('annulment.table.refundable') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.refundable?'Si':'No'}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="150"  :label="$t('annulment.table.contracts') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container  text-center">
                                        <label>{{row.contracts}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column  min-width="150"  :label="$t('form.actions')" v-if="writting_permission">
                                <template slot-scope="{row}">
                                    <div class="text-center">
                                        <label class="mr-2 ">{{(row.main)?'Por defecto':''}}</label>

                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row)"  v-if="writting_permission"></l-switch>

                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(row._id)" v-if="writting_permission"><i class="el-icon-edit"></i></a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>
        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1"  @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>

    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import AnnulmentService from '../../../js/services/AnnulmentService';
    import ContractService from '../../../js/services/ContractService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import moment from 'moment';
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    const annulmentService = new AnnulmentService();
    const contractService = new ContractService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();
    import { mapGetters } from "vuex";

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["currentUser","selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                info_page:{
                    original_info:{},
                    section_name: 'politic',
                    status: true,
                    actives: 0,
                    inactives: 0,
                    contractResponse:[],

                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                section: 'ROOMS_RATES'
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {

            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='annulments'){
                        //this.$refs.topProgress.start();
                        this.chargerItems(property_id);
                    }
                })
            },

            async chargerItems(property_id){

                this.initLoading();

            let contractResponse = await contractService.getContracts('true', property_id);
            contractResponse = contractResponse.data.data.rateplans;

            this.info_page.contractResponse = contractResponse;
            this.change_status('active');
                this.configLogs(property_id, this.info_page.section_name);


            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },



            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];
                this.$store.dispatch('CHANGES_ACTIVES', false);

            },

            updateStatus(row){
                let records = this.records;
                let id = row._id, status = row.status, rateplanNumbers = row.contracts;

               //console.log('contratos, activos', row.status);

                if(rateplanNumbers===0){
                    status = !status;


                    this.$store.dispatch('CHANGES_ACTIVES', true);
                    let idx = records.findIndex(record => record._id === id);
                    if(idx>=0){
                        records.splice(idx, 1);
                    }else{
                        records.push({
                            _id: id,
                            status: status
                        })
                    }
                }else{
                    this.alertError(row, row.contracts_links);
                }


            },

            saveChanges(){
                //console.log'items modificados', this.records)
                let records = this.records;

                this.initLoading('Actualizando políticas de cancelación');
                records.forEach(async (item, idx)=>{
                    let status =item.status;
                    let id =item._id;
                    let info_item = this.tableData.find((tableRow) => tableRow._id === id);
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)


                   //console.log('indexToDelete', indexToDelete, this.tableData, info_item)

                    if (indexToDelete >= 0) {

                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        /*info_item = this.items_i[indexToDelete];
                        console.log('indexToDelete', info_item)*/
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'false';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        /*info_item = this.items[indexToDelete];
                        console.log('indexToDelete', info_item)*/
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'true';
                    }

                    let annulment_resp = await annulmentService.updateStatus(id, status)
                    //console.log('contract response', contract.data.data.rateplan)
                    let annulment = annulment_resp.data.data.cancellationPolicy;
                   //console.log('info_item', info_item)

                    if(annulment.status===true){
                        await this.items.push(info_item)
                        let data =  [{
                            parameter : "status",
                            old : false,
                            new : true
                        }];
                        await this.createLog(annulment._id, 1, data);
                    }else{
                        await this.items_i.push(info_item)
                        await this.createLog(annulment._id, 2, []);
                    }
                    this.info_page.actives =  this.items.length;
                    this.info_page.inactives =  this.items_i.length;
                    //await this.records.splice(idx, 1);


                    /*.then(response => {

                     let contract = response.data.data.rateplan;
                     //console.log(response.data, 'respuesta')

                     });*/

                });
                setTimeout(() => {
                    this.closeLoading();
                }, 500);
                this.records = [];

                /*setTimeout(() => {
                let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                if (indexToDelete >= 0) {
                    this.tableData.splice(indexToDelete, 1)
                }

                if(status===true){
                    indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                    if (indexToDelete >= 0) {
                        this.items_i.splice(indexToDelete, 1)
                    }
                    status = 'true';

                }else{
                    indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                    if (indexToDelete >= 0) {
                        this.items.splice(indexToDelete, 1)
                    }
                    status = 'false';
                }

                annulmentService.updateStatus(id, status).then(response => {

                    let annulment = response.data.data.cancellationPolicy;
                    if(annulment.status===true){
                        this.items.push(annulment);
                    }else{
                        this.items_i.push(annulment)
                    }

                    this.info_page.actives =  this.items.length;
                    this.info_page.inactives =  this.items_i.length;
                });
            }, 800);*/


            },
            change_status2(status){
                this.info_page.status=status;
                //console.log(this.info_page.actives, this.info_page.inactives)

                if(status){
                    this.tableData =  this.items;
                }else{

                    this.tableData =  this.items_i;
                }
            },
            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;
                let contractResponse = this.info_page.contractResponse;

                let property_id = this.selected_property._id;
                let params = {};

                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true
                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        status: false
                    }
                }
                console.log('params', params);

                /*let responseRequest =  await roomService.getRoomsList(params)
                responseRequest = responseRequest.data.data.rooms;
                this.info_page.actives = responseRequest.length;
                this.tableData = responseRequest;
                this.closeLoading();*/








                //console.log('contractResponse', contractResponse)
                annulmentService.getAnnulmentsList(params).then(response => {
                    //console.log'propiedades', response.data.data.cancellationPolicys);
                    let annulments = response.data.data.cancellationPolicys;
                    annulments.forEach((item, idx) => {
                        //item.contracts = 0;
                        let rateplanAct = 0;

                        item.contracts_links = [];

                        let responseAux =  contractResponse.filter(rateC => rateC.cancellation_policy === item._id)
                        if(responseAux){
                            //console.log('responseAux', responseAux)
                            responseAux.forEach(contract=>{
                                item.contracts_links.push(contract.name)
                            })
                            rateplanAct = responseAux.length;
                        }


                        item.contracts = rateplanAct;

                        /*contractService.getRateConfig({cancellation_policy:item._id}).then(async response => {
                            let rateplanAct = 0;
                            let ratesConfigs = response.data.data.rateconfigs;






                            console.log('item 1', item.name, item._id);
                            contractResponse.forEach(item2=>{

                                console.log('item 2', item2._id, item2.cancellation_policy);
                                let responseAux = ratesConfigs.find(rateC => rateC.rateplan === item2._id)
                                if(responseAux){

                                    //console.log('item 2', item.name);
                                    rateplanAct++;
                                }

                                //console.log('responseAux', rateplanAct);
                            })


                            //console.log('rtcfg', rateplanAct);
                            item.contracts = rateplanAct;
                        });*/
                    });
                    //console.log('items a',  this.items);
                    this.info_page.actives = annulments.length;
                    this.tableData = annulments;
                    this.closeLoading();
                });


            },

            handleEdit(id) {
                this.$router.push({
                    name: 'editAnnulment',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        annulmentService.deleteAnnulment(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });
                    }
                });



            },
            createItem(){

                this.$router.push({
                    name: 'createAnnulment'
                })
            },
            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },

            getName(arr, id) {
                //console.log(id);
                let obj = arr.find(o => o._id === id);
               //console.log(obj);
                return  obj.name;
            },
            alertError(row, contracts){
               //console.log('alertError', row, contracts)

                if(contracts.length>0){
                    let status = row.status
                    let htmlList= '<ul class="ml-3" style="text-align: left">'
                    contracts.forEach(contract=>{
                        htmlList +='<li>'+contract+'</li>';
                    })

                    htmlList+= '</ul>'


                    let htmlResponse = '<p>No puedes desactivar la política, esta siendo aplicada en:</p>';

                    htmlResponse += htmlList;

                    swal({
                        title: 'Acción cancelada',
                        type: 'warning',
                        html:htmlResponse,
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: 'Cancelar',
                        buttonsStyling: false
                    }).then((result)=> {
                       //console.log('estatus a cambiar', status)

                        row.status = !status;

                    });
                }

            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }

                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }


        },
        mounted() {
            this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style>
</style>
